// eslint-disable-next-line no-unused-expressions,func-names
(function ($) {
  const textWrapper = document.querySelector('.ml7 .letters');
  textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

  anime.timeline({ loop: true })
    .add({
      targets: '.ml7 .letter',
      translateY: ['1.1em', 0],
      translateX: ['0.55em', 0],
      translateZ: 0,
      rotateZ: [180, 0],
      duration: 750,
      easing: 'easeOutExpo',
      delay: (el, i) => 50 * i,
    }).add({
      targets: '.ml7',
      opacity: 0,
      duration: 1000,
      easing: 'easeOutExpo',
      delay: 1000,
    });
});

$('#heroVideo').click(function(){
  $(this).prop("muted", !$(this).prop("muted"));
  $('.image-video').toggleClass('full-screen');
});

$('#mobileHeroVideo').click(function(){
  this.paused ? this.play() : this.pause();
  $('.play-overlay').hide();
});

$('.play-overlay').click(function(){
  var video = $('#mobileHeroVideo').get(0)
  video.paused ? video.play() : video.pause();
  $(this).hide();
});
